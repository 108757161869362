/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import { useAuth } from '../core/Auth'
import accessService from '../../../service/access'
import userService from '../../../service/user'
import { toast } from 'react-toastify'
import ContactInfo from './ContactInfo'

const loginSchema = Yup.object().shape({
    username: Yup.string().required('Vui lòng điền tên tài khoản'),
    password: Yup.string().required('Vui lòng điền mật khẩu'),
})

interface LocationState {
    username?: string
    password?: string
    message?: string
}

export function Login() {
    const location = useLocation();
    const [loading, setLoading] = useState(false)
    const { saveAuth, setCurrentUser } = useAuth()

    const { username, password, message } = location.state as LocationState || {};

    const formik = useFormik({
        initialValues: {
            username: username || '',
            password: password || ''
        },
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            try {
                const auth = await accessService.login(values.username, values.password)
                saveAuth(auth)
                const data = await userService.getInfo()
                if (data) {
                    setCurrentUser(data)
                }
            } catch (error) {
                console.log(error)
                saveAuth(undefined)
                //@ts-ignore
                setStatus(error?.response?.data?.message)
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    useEffect(() => {
        if (message) {
            toast.success(message);
        }
    }, [message]);


    return (
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            <div className='text-center mb-11'>
                <h1 className='text-dark fw-bolder mb-3'>Đăng nhập</h1>
            </div>

            {formik.status && (
                <div className='mb-10 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            )}

            <div className='fv-row mb-5'>
                {/* <label className='form-label fs-6 fw-bolder text-dark'>Tên tài khoản</label> */}
                <input
                    placeholder='Tên tài khoản'
                    {...formik.getFieldProps('username')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.username && formik.errors.username },
                        {
                            'is-valid': formik.touched.username && !formik.errors.username,
                        }
                    )}
                    name='username'
                    autoComplete='off'
                />
                {formik.touched.username && formik.errors.username && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.username}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className='fv-row mb-3'>
                {/* <label className='form-label fw-bolder text-dark fs-6 mb-0'>Mật khẩu</label> */}
                <input
                    type='password'
                    autoComplete='off'
                    placeholder='Mật khẩu'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control bg-transparent',
                        {
                            'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                            'is-valid': formik.touched.password && !formik.errors.password,
                        }
                    )}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                <div />

                <Link to='/auth/forgot-password' className='link-primary'>
                    Quên mật khẩu ?
                </Link>
            </div>

            <div className='d-grid mb-10'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>Đăng nhập</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Vui lòng chờ...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>

            <div className='text-gray-500 text-center fw-semibold fs-6'>
                Chưa có tài khoản?{' '}
                <Link to='/auth/registration' className='link-primary'>
                    Đăng kí
                </Link>
            </div>

            <ContactInfo />
        </form>
    )
}