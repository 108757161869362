/* eslint-disable react/jsx-no-target-blank */
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { Fragment } from 'react'
import { useAuth } from '../../../../../app/modules/auth'

interface IMenu {
    to: string
    hidden?: boolean
    icon?: string
    title: string
    fontIcon?: string
    hasBullet?: boolean
    children?: IMenu[]
    separate?: string
}

const SidebarMenuMain = () => {
    const { currentUser } = useAuth()

    const menu: IMenu[] = [
        {
            to: '/vxmm',
            icon: 'abstract-28',
            fontIcon: 'bi-layers',
            title: 'Vòng xoay may mắn',
            children: [
                {
                    to: '/vxmm/analytics',
                    title: 'Phân tích tổng quan',
                    hasBullet: true
                },
                {
                    to: '/vxmm/orders',
                    title: 'Danh sách đơn hàng',
                    hasBullet: true
                },
                {
                    to: '/vxmm/bot',
                    title: 'Danh sách bot',
                    hasBullet: true
                },
                {
                    to: '/vxmm/trade-logs',
                    title: 'Lịch sử giao dịch',
                    hasBullet: true
                },
                {
                    to: '/vxmm/dashboard-manager',
                    title: 'Quản lí trang đua top',
                    hasBullet: true,
                    hidden: !(
                        currentUser?.role === 'PREMIUM' || currentUser?.role === 'ADMIN'
                    ),
                },
            ],
        },
        {
            to: '/admin',
            icon: 'abstract-28',
            fontIcon: 'bi-app-indicator',
            title: 'Quản lí người dùng',
            hidden: currentUser?.role !== 'ADMIN',
        },
    ]

    return (
        <>
            {menu.map((item) => {
                const getChildren = (menu: IMenu) => (
                    <SidebarMenuItemWithSub
                        key={menu.to}
                        to={menu.to}
                        title={menu.title}
                        icon={menu.icon}
                        fontIcon={menu.fontIcon}
                        hasBullet={menu.hasBullet}
                    >
                        {menu?.children &&
                            menu?.children.map((child) =>
                                child.hidden ? null : child.children ? (
                                    getChildren(child)
                                ) : (
                                    <SidebarMenuItem
                                        key={child.to}
                                        to={child.to}
                                        icon={child.icon}
                                        title={child.title}
                                        fontIcon={child.fontIcon}
                                        hasBullet={child.hasBullet}
                                    />
                                )
                            )}
                    </SidebarMenuItemWithSub>
                )
                if (item.hidden) return null
                return (
                    <Fragment key={item.to}>
                        {!item.children && (
                            <SidebarMenuItem
                                to={item.to}
                                icon={item.icon}
                                title={item.title}
                                fontIcon={item.fontIcon}
                            />
                        )}
                        {item.children && getChildren(item)}
                        {item.separate && (
                            <div className='menu-item'>
                                <div className='menu-content pt-8 pb-2'>
                                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                                        {item.separate}
                                    </span>
                                </div>
                            </div>
                        )}
                    </Fragment>
                )
            })}
        </>
    )
}

export { SidebarMenuMain }
