import './css/ContactInfo.css'

const ContactInfo = () => {
    return (
        <div className='text-center mt-10'>
            <div className='fw-bold mb-2'>Liên hệ với tôi:</div>
            <div className='d-flex justify-content-center gap-3'>
                <a href='https://www.facebook.com/quannh.yN/' target='_blank' rel='noopener noreferrer' className='btn btn-social-icon btn-outline-primary'>
                    <img src='/media/icons/facebook-icon.svg' alt="Facebook" className='social-icon' />
                </a>
                <a href='https://zalo.me/0916387625' target='_blank' rel='noopener noreferrer' className='btn btn-social-icon btn-outline-primary'>
                    <img src='/media/icons/zalo-icon.svg' alt="Zalo" className='social-icon' />
                </a>
            </div>
        </div>
    );
}

export default ContactInfo;