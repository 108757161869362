import { createRoot } from 'react-dom/client'
// Axios
import axios from 'axios'
import { Chart, registerables } from 'chart.js'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
// Apps
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import { AppRoutes } from './app/routing/AppRoutes'
import { AuthProvider, setupAxios } from './app/modules/auth'
import { formatNumber } from './app/utils'
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';

setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})

const container = document.getElementById('root')
if (container) {
    createRoot(container).render(
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <AppRoutes />
            </AuthProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    )
}


export function getProfit(profit: number) {
    return profit > 0 ? (
        <span className='inline-flex items-center text-success'>
            <BsArrowUp />
            {formatNumber(profit)} xu
        </span>
    ) : profit < 0 ? (
        <span className='inline-flex items-center text-danger'>
            <BsArrowDown />
            {formatNumber(Math.abs(profit))} xu
        </span>
    ) : (
        <span>0</span>
    );
}
