import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ToastContainer} from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

const App = () => {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <LayoutProvider>
                <AuthInit>
                    <Outlet />
                    <MasterInit />
                    <ToastContainer position="bottom-center" autoClose={1000} />
                </AuthInit>
            </LayoutProvider>
        </Suspense>
    )
}

export {App}
