import { Modal } from 'react-bootstrap'
import { KTIcon } from '../../../../_metronic/helpers'
import { useEffect, useState } from 'react'
import userService from '../../../../app/service/user'
import { format } from 'date-fns'
import { getRoleSpan } from '../..'
import { UserModel } from '../../../../app/modules/auth'

type Props = {
    show: boolean
    handleClose: () => void
}

const UserInfoField = ({ label, value }: { label: string, value: string | JSX.Element }) => (
    <div className='mb-4'>
        <label className='form-label fs-6 fw-bolder text-black'>{label}</label>
        <span className='text-black'> {value}</span>
    </div>
);

function UserInfoModal({ show, handleClose }: Props) {
    const [userInfo, setUserInfo] = useState<UserModel>();

    useEffect(() => {
        const getUserInfo = async () => {
            const userInfo = await userService.getInfo();
            setUserInfo(userInfo);
        };
        getUserInfo();
    }, []);

    return (
        <Modal
            id='kt_modal_user_info'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px'
            show={show}
            onHide={handleClose}
            backdrop='static'
        >
            <div className='modal-header'>
                <h3 className='modal-title'>Thông tin cá nhân</h3>
                <button type='button' className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTIcon className='fs-1' iconName='cross' />
                </button>
            </div>

            <div className='modal-body px-lg-10'>
                <UserInfoField label='Tên tài khoản:' value={userInfo?.username || ''} />
                <UserInfoField label='Tên người dùng:' value={userInfo?.name || ''} />
                <UserInfoField label='Email:' value={userInfo?.email || ''} />
                <UserInfoField label='Ngày tạo tài khoản:' value={userInfo?.registrationDate ? format(userInfo.registrationDate, 'dd/MM/yyyy HH:mm:ss') : ''}/>
                <UserInfoField label='Loại tài khoản:' value={getRoleSpan(userInfo?.role)} />
            </div>
        </Modal>
    )
}

export default UserInfoModal