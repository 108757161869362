/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react'
import { ILayout, useLayout } from '../../core'
import './Footer.css'

const Footer = () => {
    const { config } = useLayout();

    useEffect(() => {
        updateDOM(config);
    }, [config]);

    return (
        <div className="footer-container">
            <div className="footer-text">
                © {new Date().getFullYear()} Bản quyền thuộc về hQ
            </div>
            <a href='https://www.facebook.com/quannh.yN/' target='_blank' rel='noopener noreferrer' className="contact-me">
                <img src='/media/icons/facebook-icon.svg' alt="Facebook" />
            </a>
            <a href='https://zalo.me/0916387625' target='_blank' rel='noopener noreferrer' className="contact-me">
                <img src='/media/icons/zalo-icon.svg' alt="Zalo" />
            </a>
        </div>
    );
};

const updateDOM = (config: ILayout) => {
    if (config.app?.footer?.fixed?.desktop) {
        document.body.classList.add('data-kt-app-footer-fixed', 'true')
    }

    if (config.app?.footer?.fixed?.mobile) {
        document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
    }
}

export { Footer }
