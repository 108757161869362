/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useAuth } from '../core/Auth'
import accessService from '../../../service/access'
import userService from '../../../service/user'
import { toast } from 'react-toastify'
import ContactInfo from './ContactInfo'

const initialValues = {
    username: '',
    name: '',
    email: '',
    password: '',
    retypePassword: '',
}

const registrationSchema = Yup.object().shape({
    username: Yup.string()
        .min(6, 'Tên tài khoản phải có tối thiểu 6 kí tự')
        .max(20, 'Tên tài khoản chỉ được tối đa 20 kí tự')
        .required('Vui lòng điền vào trường này')
        .lowercase('Tên tài khoản phải là chữ thường')
        .strict(),
    name: Yup.string()
        .min(3, 'Tên người dùng phải có tối thiểu 3 kí tự')
        .max(30, 'Tên người dùng chỉ được tối đa 30 kí tự')
        .required('Vui lòng điền vào trường này'),
    email: Yup.string()
        .required('Vui lòng điền vào trường này')
        .email('Email không hợp lệ'),
    password: Yup.string()
        .min(8, 'Mật khẩu phải có tối thiểu 8 kí tự')
        .required('Vui lòng điền vào trường này'),
    retypePassword: Yup.string()
        .required('Vui lòng điền vào trường này')
        .oneOf([Yup.ref('password')], 'Mật khẩu không khớp'),
})

export function Registration() {
    const [loading, setLoading] = useState(false)
    const { saveAuth, setCurrentUser } = useAuth()
    const navigate = useNavigate()
    const formik = useFormik({
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            try {
                const res = await accessService.register(
                    values.username,
                    values.name,
                    values.email,
                    values.password
                )
                // saveAuth(auth)
                // const data = await userService.info()

                // if (data) {
                //     setCurrentUser(data)
                // }
                navigate('/auth', {
                    replace: true,
                    state: {
                        username: values.username,
                        password: values.password,
                        //@ts-ignore
                        message: res.message
                    }
                })
            } catch (error) {
                saveAuth(undefined)
                //@ts-ignore
                setStatus(error?.response?.data?.message)
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    useEffect(() => {
        PasswordMeterComponent.bootstrap()
    }, [])

    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
        >
            <div className='text-center mb-11'>
                <h1 className='text-dark fw-bolder mb-3'>Đăng kí</h1>
            </div>
            {formik.status && (
                <div className='mb-10 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            )}

            {/* begin::Form group Email */}
            <div className='fv-row mb-5'>
                <input
                    type='text'
                    autoComplete='off'
                    placeholder='Tên tài khoản'
                    {...formik.getFieldProps('username')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.username && formik.errors.username },
                        {
                            'is-valid': formik.touched.username && !formik.errors.username,
                        }
                    )}
                />
                {formik.touched.username && formik.errors.username && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.username}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className='fv-row mb-5'>
                <input
                    type='text'
                    autoComplete='off'
                    placeholder='Tên người dùng'
                    {...formik.getFieldProps('name')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.name && formik.errors.name },
                        { 'is-valid': formik.touched.name && !formik.errors.name, }
                    )}
                />
                {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.name}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className='fv-row mb-5'>
                <input
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    placeholder='Email'
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        { 'is-valid': formik.touched.email && !formik.errors.email, }
                    )}
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.email}</span>
                        </div>
                    </div>
                )}
            </div>

            {/* begin::Form group Password */}
            <div className='fv-row mb-5' data-kt-password-meter='true'>
                <div className='mb-1'>
                    <div className='position-relative mb-3'>
                        <input
                            type='password'
                            autoComplete='off'
                            {...formik.getFieldProps('password')}
                            placeholder='Mật khẩu'
                            className={clsx(
                                'form-control bg-transparent',
                                { 'is-invalid': formik.touched.password && formik.errors.password, },
                                { 'is-valid': formik.touched.password && !formik.errors.password, }
                            )}
                        />
                        {formik.touched.password && formik.errors.password && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.password}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group Confirm password */}
            <div className='fv-row mb-5'>
                <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('retypePassword')}
                    placeholder='Xác nhận mật khẩu'
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.retypePassword && formik.errors.retypePassword, },
                        { 'is-valid': formik.touched.retypePassword && !formik.errors.retypePassword, }
                    )}
                />
                {formik.touched.retypePassword && formik.errors.retypePassword && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.retypePassword}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary w-100 mb-10'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>Đăng kí</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Vui lòng chờ...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>

                <div className='text-gray-500 text-center fw-semibold fs-6'>
                    Đã có tài khoản?{' '}
                    <Link to='/auth/login' className='link-primary'>
                        Đăng nhập
                    </Link>
                </div>
            </div>

            <ContactInfo />
        </form>
    )
}
