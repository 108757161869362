import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import ContactInfo from './ContactInfo'
import accessService from '../../../service/access'
import { toast } from 'react-toastify'

const initialValues = {
    username: '',
    email: ''
}

const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email('Email không đúng định dạng!')
        .required('Vui lòng điền vào trường này!'),
})

export function ForgotPassword() {
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

    const formik = useFormik({
        initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            try {
                const res = await accessService.forgotPassword(
                    values.username,
                    values.email
                )
                setSubmitting(false)
                setLoading(false)
                formik.resetForm()
                setStatus(res?.message)
            } catch (error) {
                //@ts-ignore
                setStatus(error?.response?.data?.message)
                setSubmitting(false)
                setLoading(false)
            }
        }
    })

    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
        >
            <div className='text-center mb-10'>
                <h1 className='text-dark fw-bolder mb-3'>Quên Mật Khẩu</h1>
            </div>

            {formik.status && (
                <div className='mb-10 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            )}

            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Tên tài khoản</label>
                <input
                    type='text'
                    placeholder=''
                    autoComplete='off'
                    {...formik.getFieldProps('username')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.username && formik.errors.username },
                        {
                            'is-valid': formik.touched.username && !formik.errors.username,
                        }
                    )}
                />
                {formik.touched.username && formik.errors.username && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.username}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
                <input
                    type='email'
                    placeholder=''
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        {
                            'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.email}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className='d-grid mb-10'>
                <button
                    type='submit'
                    id='kt_password_reset_submit'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {
                        !loading && <span className='indicator-label'>Đồng ý</span>
                    }
                    {
                        loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Đang xử lý...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )
                    }
                </button>
            </div>

            <div className='text-gray-500 text-center fw-semibold fs-6'>
                Đã có tài khoản?{' '}
                <Link to='/auth/login' className='link-primary'>
                    Đăng nhập
                </Link>
            </div>

            <ContactInfo />
        </form>
    )
}
