import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { useAuth } from '../modules/auth'
import 'react-quill/dist/quill.snow.css'

const PrivateRoutes = () => {
    const { currentUser } = useAuth()
    const VxmmPage = lazy(() => import('../modules/vxmm/VxmmPage'))
    const AdminPage = lazy(() => import('../modules/admin/AdminPage'))

    return (
        <Routes>
            <Route element={<MasterLayout />}>
                <Route path='auth/*' element={<Navigate to='/vxmm/analytics' />} />
                <Route
                    path='vxmm/*'
                    element={
                        <SuspensedView>
                            <VxmmPage />
                        </SuspensedView>
                    }
                />
                {currentUser?.role === 'ADMIN' && (
                    <Route
                        path='admin'
                        element={
                            <SuspensedView>
                                <AdminPage />
                            </SuspensedView>
                        }
                    />
                )}

                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
