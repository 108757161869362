import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'

import { KTIcon } from '../../../../_metronic/helpers'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useState } from 'react'
import accessService from '../../../../app/service/access'
import { toast } from 'react-toastify'

type Props = {
    show: boolean
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const resetPasswordSchema = Yup.object().shape({
    inputPassword: Yup.string()
        .min(3, 'Mật khẩu tổi thiểu 3 kí tự')
        .max(50, 'Mật khẩu tối đa 50 kí tự')
        .required('Vui lòng điền mật khẩu'),
    newPassword: Yup.string()
        .min(3, 'Mật khẩu tổi thiểu 3 kí tự')
        .max(50, 'Mật khẩu tối đa 50 kí tự')
        .required('Vui lòng điền mật khẩu'),
    reTypeNewPassword: Yup.string()
        .min(3, 'Mật khẩu tổi thiểu 3 kí tự')
        .max(50, 'Mật khẩu tối đa 50 kí tự')
        .required('Vui lòng điền mật khẩu')
        .oneOf([Yup.ref('newPassword')], 'Mật khẩu nhập lại không khớp'),
})

const initialValues = {
    inputPassword: '',
    newPassword: '',
    reTypeNewPassword: '',
}

function ResetPasswordModal({ show, handleClose }: Props) {
    const formik = useFormik({
        validationSchema: resetPasswordSchema,
        initialValues,
        onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
            setLoading(true)
            try {
                const res = await accessService.changePassword(values.inputPassword, values.newPassword)
                toast.info(res?.message)
                setLoading(false)
                resetForm()
                handleClose()
            } catch (error) {
                //@ts-ignore
                setStatus(error?.response?.data?.message)
                setSubmitting(false)
                setLoading(false)
            }
        },
    })
    const [loading, setLoading] = useState(false)

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <div className='modal-header'>
                <h3>Đổi mật khẩu</h3>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTIcon className='fs-1' iconName='cross' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-lg-10 px-lg-10'>
                {formik.status && (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                    </div>
                )}
                <div className='fv-row mb-8'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Mật khẩu cũ</label>
                    <input
                        type='password'
                        placeholder='Nhập mật khẩu cũ'
                        {...formik.getFieldProps('inputPassword')}
                        className={clsx(
                            'form-control bg-transparent',
                            { 'is-invalid': formik.touched.inputPassword && formik.errors.inputPassword },
                            {
                                'is-valid': formik.touched.inputPassword && !formik.errors.inputPassword,
                            }
                        )}
                        name='inputPassword'
                        autoComplete='off'
                    />
                    {formik.touched.inputPassword && formik.errors.inputPassword && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.inputPassword}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-8'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Mật khẩu mới</label>
                    <input
                        type='password'
                        placeholder='Nhập mật khẩu mới'
                        {...formik.getFieldProps('newPassword')}
                        className={clsx(
                            'form-control bg-transparent',
                            { 'is-invalid': formik.touched.newPassword && formik.errors.newPassword },
                            {
                                'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                            }
                        )}
                        name='newPassword'
                        autoComplete='off'
                    />
                    {formik.touched.newPassword && formik.errors.newPassword && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.newPassword}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-8'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Mật khẩu mới</label>
                    <input
                        type='password'
                        placeholder='Nhập lại mật khẩu mới'
                        {...formik.getFieldProps('reTypeNewPassword')}
                        className={clsx(
                            'form-control bg-transparent',
                            { 'is-invalid': formik.touched.reTypeNewPassword && formik.errors.reTypeNewPassword },
                            {
                                'is-valid': formik.touched.reTypeNewPassword && !formik.errors.reTypeNewPassword,
                            }
                        )}
                        name='reTypeNewPassword'
                        autoComplete='off'
                    />
                    {formik.touched.reTypeNewPassword && formik.errors.reTypeNewPassword && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.reTypeNewPassword}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='modal-footer'>
                <button
                    type='button'
                    className='btn btn-light'
                    data-bs-dismiss='modal'
                    onClick={handleClose}
                >
                    Hủy
                </button>
                <button
                    type='button'
                    className='btn btn-danger'
                    onClick={() => formik.handleSubmit()}
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>Đổi mật khẩu</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Vui lòng chờ...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default ResetPasswordModal
