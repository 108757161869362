import React from 'react'

const colors = {
    Bokken: '#3699ff',
    Shuriken: '#1bc5bd',
    Tessen: '#f64e60',
    Kunai: '#ffa800',
    Katana: '#8950fc',
    Tone: '#e1e3ea',
    Sanzu: '#181c32',
    Sensha: '#3699ff',
    Fukiya: '#f64e60 ',
}

const getColorByServerName = (serverName: IServerName): string => colors[serverName]

function getColorNameByServerName(serverName: string) {
    switch (serverName) {
        case 'Bokken':
            return 'primary'
        case 'Shuriken':
            return 'info'
        case 'Tessen':
            return 'danger'
        case 'Kunai':
            return 'warning'
        case 'Katana':
            return 'purple'
        case 'Tone':
            return 'success'
        case 'Sanzu':
            return 'dark'
        case 'Sensha':
            return 'primary'
        case 'Fukiya':
            return 'danger'
        default:
            return 'success'        
    }
}

const getServerAvatar = (serverName: string) => {
    const color = getColorNameByServerName(serverName)
    const index =
        [
            'Bokken',
            'Shuriken',
            'Tessen',
            'Kunai',
            'Katana',
            'Tone',
            'Sanzu',
            'Sensha',
            'Fukiya',
        ].indexOf(serverName) + 1
    return React.createElement(
        'span',
        {
            className: `symbol-label bg-light-${color} text-${color}`,
        },
        `SV${index}`
    )
}

export function formatNumber(num: number) {
    return num.toLocaleString('en-US');
}

export { getColorByServerName, getColorNameByServerName, getServerAvatar }
