import { UserModel } from '../modules/auth'
import axiosInstance from '../utils/axios'

export interface IUserSetting {
	webTitle: string
	theme: string
	showModal: boolean
	hideTopInfo: boolean
	numPlayerTopMonth: number
	numPlayerTopDay: number
	modalContent: string
	notificationContent: string
	topRewardContent: string
	linkReportError: string
	limitOrderHistory: boolean
	hideOrderInfo: boolean
	hideOrderCoin: boolean
	numOrderHistory: number
	showTableTopJoinMonth: boolean
	showTableTopWinMonth: boolean
	showTopLastMonth: boolean
	// showTableTopJoinLastMonth: boolean
	// showTableTopWinLastMonth: boolean
	showTableTopJoinDay: boolean
	showTableTopWinDay: boolean
	showTopLastDay: boolean
	betStatusTitle: string
	loseStatusTitle: string
	winStatusTitle: string
	rewardStatusTitle: string
	coinRewardTopJoinMonth: string[]
	coinRewardTopWinMonth: string[]
	coinRewardTopJoinDay: string[]
	coinRewardTopWinDay: string[]
	description: string
	hiddenServerTagIds: number[]
}

interface UserConfig {
	active: boolean
	notification: {
		title: string
		modalContent: string
		useModal: boolean
		notificationMessage: string
	}
}

interface UserOrders {
	active: boolean
	data: {
		totalPage: number
		totalElement: number
		orders: {
			id: number
			serverId: number
			serverName: IServerName
			botName: string
			playerName: string
			second: number
			coin: string
			type: number
			status: number
			winCoin: number
			rewardCoin: number
			timeCreate: string
			timeClose: string
		}[]
	}
}

interface IUserTopPlayers {
	active: boolean
	data: ITop[]
}

interface IUserBots {
	serverTag: {
		serverId: number
		serverName: string
	}[]
	botTag: {
		totalPage: number
		totalElement: number
		data: {
			charname: string
			map: string
			zone: number
			status: boolean
		}[]
	}
}

const userService = {
	getInfo: async (): Promise<UserModel> => await axiosInstance.get('/user/info'),
	getSetting: async (): Promise<IUserSetting> => await axiosInstance.get('/user/setting'),
	saveSetting: async (setting: IUserSetting) => await axiosInstance.put('/user/setting', setting),
	getConfig: async (username: string): Promise<UserConfig> => await axiosInstance.get(`/user/public/${username}/config`),
	getOrders: async (username: string, page: number, limit: number): Promise<UserOrders> => await axiosInstance.get(`/user/public/${username}/orders`, { params: { page, limit } }),
	getTopPlayer: async (username: string, options: ITopPlayerOptions): Promise<IUserTopPlayers> =>
		await axiosInstance.get(`/user/public/${username}/top-player`, {
			params: {
				'type-lucky': options.typeLucky,
				'type-top': options.typeTop,
				'type-date': options.typeDate,
			},
		}),
	getBots: async (
		username: string,
		options: {
			serverId: number
			page: number
			limit: number
		},
	): Promise<IUserBots> =>
		await axiosInstance.get(`/user/public/${username}/bots`, {
			params: {
				'server-id': options.serverId,
				page: options.page,
				limit: options.limit,
			},
		}),
}

export default userService
