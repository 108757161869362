import { FC, useState } from 'react'
import { useAuth } from '../../../../app/modules/auth'
import { toAbsoluteUrl } from '../../../helpers'
import ResetPasswordModal from './ResetPasswordModal'
import UserInfoModal from './UserInfoModal'
import { getRoleSpan } from '../..'

const HeaderUserMenu: FC = () => {
    const { currentUser, logout } = useAuth()
    const [showInfoModal, setShowInfoModal] = useState(false)
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)
    return (
        <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-dark menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-250px'
            data-kt-menu='true'
        >
            <div className='menu-item px-3'>
                <div className='menu-content d-flex align-items-center px-3'>
                    <div className='symbol symbol-50px me-5'>
                        <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} />
                    </div>

                    <div className='fw-bolder d-flex align-items-center fs-7'>
                        {currentUser?.username}
                        {getRoleSpan(currentUser?.role)}
                    </div>
                </div>
            </div>

            <div className='separator my-2'></div>
            
            <div className='menu-item px-2 fs-7'>
                <a onClick={() => setShowInfoModal(true)} className='menu-link'>
                    Thông tin cá nhân
                </a>
            </div>
            <div className='menu-item px-2 fs-7'>
                <a onClick={() => setShowResetPasswordModal(true)} className='menu-link'>
                    Đổi mật khẩu
                </a>
            </div>
            <div className='menu-item px-2 fs-7'>
                <a onClick={logout} className='menu-link'>
                    Đăng xuất
                </a>
            </div>
            <UserInfoModal show={showInfoModal} handleClose={() => setShowInfoModal(false)} />
            <ResetPasswordModal show={showResetPasswordModal} handleClose={() => setShowResetPasswordModal(false)} />
        </div>
    )
}

export { HeaderUserMenu }
