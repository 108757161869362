import { AuthModel } from '../modules/auth'
import axiosInstance from '../utils/axios'

export interface IChangePassword {
    password: string
    newPassword: string
}

class AccessService {
    async login(username: string, password: string): Promise<AuthModel | undefined> {
        return await axiosInstance.post('/login', { username, password })
    }

    async register(
        username: string,
        name: string,
        email: string,
        password: string
    ): Promise<AuthModel | undefined> {
        return await axiosInstance.post('/register', { username, name, email, password })
    }

    async changePassword(
        inputPassword: string,
        newPassword: string
    ): Promise<{ message: string } | undefined> {
        return await axiosInstance.post('/user/info/password', { inputPassword, newPassword })
    }

    async forgotPassword(
        username: string,
        email: string
    ): Promise< { message: string } | undefined> {
        return await axiosInstance.post('/forgot-password', { username, email })
    }
}
const accessService = new AccessService()
export default accessService
