import {PageTitleWrapper} from './page-title'

const ToolbarWrapper = () => {
  return (
    <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
      <div id='kt_app_toolbar_container' className='app-container container-fluid'>
        <PageTitleWrapper />
      </div>
    </div>
  )
}

export {ToolbarWrapper}
