import React from 'react'
import { BsReplyAll } from 'react-icons/bs'

export * from './layout/header-menus/HeaderUserMenu'
export * from './layout/theme-mode/ThemeModeProvider'
export * from './layout/theme-mode/ThemeModeSwitcher'

export function getRoleColor(role: any) {
    let color = ''
    switch (role) {
        case 'ADMIN':
            color = 'success'
            break;
        case 'GUEST':
            color = 'primary'
            break;
        case 'VIP':
            color = 'danger'
            break;
        case 'PREMIUM':
            color = 'warning'
            break;
        default:
            color = 'secondary'
            break;
    }
    return color

}

export function getRoleSpan(role: any) {
    let className = ''
    switch (role) {
        case 'ADMIN':
            className = 'badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'
            break;
        case 'GUEST':
            className = 'badge badge-light-primary fw-bolder fs-8 px-2 py-1 ms-2'
            break;
        case 'VIP':
            className = 'badge badge-light-danger fw-bolder fs-8 px-2 py-1 ms-2'
            break;
        case 'PREMIUM':
            className = 'badge badge-light-warning fw-bolder fs-8 px-2 py-1 ms-2'
            break;
    }
    return React.createElement(
        'span',
        {
            className: className,
        },
        role
    )
}